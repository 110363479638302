// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import { P } from '../../hybrid/wrappers';
import FormInput from './components/form-input/FormInput';
import ToggleInput from './components/form-input/ToggleInput';
import FormInputDisabled from './components/FormDisabledInfo';
import FormInputDropzone from './components/form-image/FormDropzone';
import FormCheckboxList from './components/form-checkbox/FormCheckboxList';
import FormCheckbox from './components/form-checkbox/FormCheckbox';
import FormCheckboxSwitcher from './components/form-checkbox/FormCheckboxSwitcher';
import FormSponsorWidget from './components/form-sponsor-widget/FormSponsorWidget';
import FormLoginWidget from './components/form-login-widget/FormLoginWidget';
import FormDate from './components/form-date/FormDate';
import FormChangePasswordWidget from './components/form-change-data-in-modal/FormChangeDataInModal';
import FormDropdownSearch from './components/form-dropdown/FormDropdownSearch';
import FormRadioSwitcher from './components/form-radio/FormRadioSwitcher';
import FormSelect from './components/form-dropdown/FormSelect';
import FormSelectLocation from './components/form-dropdown/FormSelectLocation';
import FormSelectLocationKz from './components/form-dropdown/FormSelectLocationKz';
import FormSelectLocationCus from './components/form-dropdown/FormSelectLocationCus';
import FormRange from './components/form-input/FormRangeValues';
import FormRadioCircles from './components/form-radio/FormRadioCircles';
import FormTextArea from './components/form-textarea/FormInputArea';
import FormProductPrices from './components/form-product-prices/FormProductPrices'
import Editor from './components/form-editor/FormEditor';
import FormColorPicker from './components/form-color/FormColorPicker';
import Captcha from '../../widgets/captcha/Captcha';
import FormMap from './components/form-map/FormMap';
import Contact from '../contact/Contact';
import SelectDataWidget from './components/select-data-widget/SelectDataWidget';
import SelectDataWidgetMulti from './components/select-data-widget-multi/SelectDataWidget';
import FormDateRange from './components/form-date/FormDateRange';
import HtmlBlock from './components/html-block/HtmlBlock';
import SubTitle from './components/subtitle/SubTitle';
import AdminProductSets from './components/form-product-sets/AdminProductSets';

const FormSwitcher = ({
    data,
    formName,
    funcs,
    formStyles,
    formData,
    setFormDataMain,
    fieldsErr,
    customData,
    sendFormData,
    message,
    setFormData,
    captchaCount,
    tryCount,
    isContainerBody
}) => {
    return (
        data.map((item, idx) => {
            const { attribute, disabled } = item;
            const propsData = {
                ...item,
                handlers: funcs?.[attribute] || {},
                compStyle: formStyles?.[attribute] || {},
                formData: formData,
                setFormData: setFormDataMain || setFormData,
                errors: fieldsErr?.[item.attribute],
                hint: item?.hint,
                disabled,
                customData: customData?.[attribute],
                key: `${attribute}${formName}${idx}`,
                message,
                routePage: item?.routePage ? item.routePage : null,
                fieldsErr: fieldsErr,
                isContainerBody: isContainerBody
            };
            switch (item.type) {
            case 'phone-widget':
            case 'password':
            case 'input':
                return <FormInput {...propsData} sender={sendFormData}/>;

            case 'toggle-input':
                return <ToggleInput {...propsData} />;
            case 'dropdown':
            case 'select':
                return <FormSelect {...propsData} />;

            case 'checkbox-list':
                return <FormCheckboxList {...propsData} />;
            case 'checkbox':
                return <FormCheckbox {...propsData} />;
            case 'checkbox-switcher':
                return <FormCheckboxSwitcher {...propsData} />;
            case 'radio-list':
                return <FormRadioCircles {...propsData} />;

            case 'sponsor-widget':
                return <FormSponsorWidget {...propsData} />;
            case 'login-widget':
                return <FormLoginWidget {...propsData} />;

            case 'select-data-widget':
            case 'admin-sponsor-widget':
                return <SelectDataWidget {...propsData} />;
            case 'select-data-widget-multi':
                return <SelectDataWidgetMulti {...propsData} />;

            case 'range-date-widget':
                return (
                    <FormDateRange
                        {...propsData}

                    />
                );
            case 'date-widget':
                return <FormDate {...propsData} />;
            case 'radio-switcher':
            case 'sex-widget':
                return <FormRadioSwitcher {...propsData} />;
            case 'location_widget':
                return <FormSelectLocation {...propsData}/>;
            case 'location_widget_kz':
                return <FormSelectLocationKz {...propsData}/>;
            case 'location_widget_cus':
                return <FormSelectLocationCus {...propsData}/>;
            case 'dropdown-search':
                return <FormDropdownSearch
                    {...propsData} />;
            case 'dropdown-multiselect':
                return <FormSelect {...propsData} multiple={true}/>;
            case 'image':
            case 'dropzone':
                return <FormInputDropzone {...propsData} />;

            case 'info-field':
                return <FormInputDisabled {...propsData} />;
            case 'range-values':
                return <FormRange {...propsData} />;

            case 'password-change-widget':
            case 'email-change-widget':
                return (
                    <FormChangePasswordWidget
                        {...propsData}
                    />
                );
                //new Form-element
            case 'textarea':
                return (
                    <FormTextArea {...propsData} sender={sendFormData}/>
                );
            case 'editor':
                return (
                    <Editor {...propsData}/>
                );
            case 'product-prices':
                return (
                    <FormProductPrices
                        {...propsData}
                        errors={fieldsErr}
                    />
                );
            case 'color-picker':
                return (
                    <FormColorPicker {...propsData}/>
                );
            case 'captcha':
                return (
                    <Captcha {...propsData} sender={sendFormData} captchaCount={captchaCount} tryCount={tryCount}/>
                );
            case 'maps':
                return (
                    <FormMap {...propsData} />
                );
            case 'contact':
                return (
                    <Contact {...propsData} />
                );
            case 'html-block':
                return <HtmlBlock {...propsData} />;
            case 'subtitle':
                return <SubTitle {...propsData} />;
            case 'admin-product-sets':
                return <AdminProductSets {...propsData} />
            default:
                return <P key={`${formName}${idx}`}>Unknown element</P>;
            }
        })
    );
};

export default FormSwitcher;