import { media } from '../../../common/utils/utils';
import officeStyles from './office/form-styles'
import mainStyles from './main/form-styles'
import uikit from '../../uikit/styles';
import {
    clean, couple_title,
    no_title, separated_subtitle, separated_title, small
} from '../../uikit/form-variations';


const stylesheets = (width, height, variable = {}) => {
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
    })(!window['safari'] || typeof safari !== 'undefined');
    return {
        officeStyles, mainStyles,

        ...separated_title(variable),
        ...separated_subtitle(variable),
        ...no_title(variable),
        ...couple_title(variable),
        ...small(variable),
        ...clean(variable),
        form: media(width, height, 'w', {
            768: { width: '100%' },
            0: {
                width: '100%',
                display: 'flex',
                'flex-direction': 'column',
                'flex-grow': 1
            }
        }),
        formGroup: media(width, height, 'w', {
            768: {
                display: 'flex',
                'flex-direction': 'column',
                'flex-wrap': 'nowrap',
                'justify-content': 'flex-start',
                'align-items': 'stretch',
                'margin-bottom': '16px',
                position: 'relative',
                width: '100%'
            },
            0: {
                display: 'flex',
                'flex-direction': 'column',
                'flex-wrap': 'nowrap',
                'justify-content': 'flex-start',
                'align-items': 'stretch',
                'margin-bottom': '8px',
                position: 'relative',
                width: '100%'
            }
        }),
        formGroupblockHandler: {
            position: 'absolute',
            background: 'rgba(143,143,171,0.6)',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            'border-radius': '12px',
            'z-index': 9,
            cursor: 'pointer',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'flex-end',
            padding: '10px'
        },
        formControlInner: {
            position: 'relative',
            'flex-grow': '1',
            'margin': '0 4px',
            'max-width': '100%'
        },
        input: media(width, height, 'w', {
            768: {
                ...uikit.text,
                'border': 'none',
                'width': '100%'
            },
            0: {
                ...isSafari ? {
                    'font-size': '16px',
                    'line-height': '1.5rem',
                    color: 'black'
                } : uikit.text,
                'border': 'none',
                'width': '100%'
            }
        }),
        formControlContentAddon: {
            'display': 'flex',
            'align-items': 'center'
        },
        formControlContent: {
            'margin': '0 -4px',
            'display': 'flex',
            'align-items': 'center',
            position: 'relative'
        },
        formTitleFocus: { color: variable.blueColor },
        formTitleError: { color: variable.redColor },

        formControlIconWrap: {
            width: '20px',
            height: '20px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'margin': '0 4px',
            'cursor': 'pointer'
        },
        formControlIconWrapMulti: {
            position: 'absolute',
            'z-index': '2',
            width: '100%',
            height: '100%',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'flex-end',
            'margin': '0 4px',
            'cursor': 'pointer',
            padding: '8px'
        },
        formControlIconWrapHover: {
            width: '20px',
            height: '20px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'margin': '0 4px',
            'cursor': 'pointer',
            opacity: '0.5'
        },
        formControlIconSotialWrap: { 'margin': '0 4px 0 0' },
        formControlSearchIconSvg: {
            fill: variable.blueColor,
            width: '18px',
            height: '18px'
        },
        formControlLoginSvg: {
            width: '16px',
            height: '16px',
            fill: variable.greyExtraDarkColor
        },
        formControlLoginSvgHover: { fill: variable.blueColor },
        formControlSocialIcon: {
            width: '20px',
            height: '20px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'margin-right': '4px',
            'cursor': 'pointer'
        },
        formControlSocialIconSvg: {
            width: '20px',
            height: '20px'
        },
        formControlAddon: media(width, height, 'w', {
            576: {
                'color': variable.greyNeutralColor,
                ...uikit.text,
                'background-color': variable.greyExtraDarkTransparentColor,
                'border-radius': '4px',
                'padding': '0px 6px',
                'margin-right': '8px',
                'display': 'inline-block'
            },
            0: {
                'color': variable.greyNeutralColor,
                ...uikit.text,
                'background-color': variable.greyExtraDarkTransparentColor,
                'border-radius': '4px',
                'padding': '0px 6px',
                'margin-right': '8px',
                'display': 'inline-block',
                'max-width': '50%',
                'overflow-x': 'scroll'
            }
        }),
        formControlContentPlaceholder: {
            position: 'relative',
            'z-index': '1',
            'flex-grow': 1,
            'max-width': '100%'
        },
        formControlContentPlaceholderArea: { display: 'flex' },
        formControlInnerPlaceholder: {
            'display': 'flex',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 0,
            'z-index': '0',
            width: '100%',
            cursor: 'text'
        },
        formControlInnerPlaceholderArea: {
            top: '0',
            transform: 'none'
        },
        placeholder: {
            'filter': 'brightness(1) opacity(0.4)',
            ...isSafari ? {
                'font-size': '16px',
                'line-height': '1.5rem'
            } : uikit.text,
            'line-height': '1.3',
            'font-weight': 'normal',
            'white-space': 'nowrap',
            'overflow': 'hidden',
            'text-overflow': 'ellipsis'
        },

        formControlDisabled: {
            background: variable.greyExtraLightColor,
            'border-color': 'transparent'
        },

        formControlRequiredMark: {
            color: variable.redColor,
            'padding-left': '4px',
            ...uikit.small
        },
        formControlRequiredMarkFocus: { color: variable.blueColor },

        toggleInputContentMobile: media(width, height, 'w', {
            768: {},
            0: { 'flex-wrap': 'wrap' }
        }),
        toggleInputInnerMobile: media(width, height, 'w', {
            768: {},
            0: {
                'width': '100%',
                'margin-bottom': '8px',
                'order': '-1'
            }
        }),
        toggleInputButton: {
            'cursor': 'pointer',
            width: '40px',
            'min-width': '40px',
            height: '40px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            background: 'transparent',
            'border-radius': '8px'
        },
        toggleInputButtonActive: { background: variable.greyExtraDarkColorTransparent },
        toggleInputButtonIcon: {
            width: '24px',
            height: '24px'
        },
        toggleInputButtonIconActive: { fill: variable.whiteColor },
        formCheckboxlistTitle: media(width, height, 'w', {
            768: {
                'margin-bottom': '16px',
                'font-weight': '500'
            },
            0: {
                'margin-bottom': '8px',
                'font-weight': '500'
            }
        }),
        formCheckboxWrapper: { width: '100%' },
        formCheckboxBlock: media(width, height, 'w', {
            768: {
                display: 'flex',
                'flex-direction': 'row',
                width: 'fit-content',
                'margin-bottom': '8px',
                ...uikit.small,
                'line-height': '1.28',
                'align-items': 'center',
                'cursor': 'pointer'
            },
            0: {
                display: 'flex',
                'flex-direction': 'row',
                width: 'fit-content',
                'margin-bottom': '4px',
                ...uikit.text,
                'line-height': '1.28',
                'align-items': 'center'
            }
        }),
        formCheckboxTitleText: media(width, height, 'w', {
            768: { ...uikit.mt3, ...uikit.mb3 },
            0: { ...uikit.mb3 }
        }),
        formCheckboxBlockDisabled: {
            'pointer-events': 'none',
            opacity: '.5'
        },
        formCheckbox: {
            width: '20px',
            height: '20px',
            'margin-right': '12px',
            'border-style': 'solid',
            'border-width': '1px',
            'border-color': variable.greyDarkColor,
            'background-color': variable.whiteColor,
            'border-radius': '6px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'flex-shrink': '0'
        },

        formCheckboxActive: {
            'border-color': variable.blueColor,
            'background-color': variable.blueColor
        },

        formCheckboxSvg: {
            width: '11px',
            height: '8px'
        },
        formSelectDataButtonsInputWrap: {
            'width': '100%',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-between',
            'flex-wrap': 'wrap'
        },
        formSelectDataInput: { 'width': '60%' },
        formSelectDataButtonsWrap: {
            display: 'flex',
            'align-items': 'center',
            'min-width': '40%',
            'justify-content': 'flex-end'
        },
        formSelectDataHelpBtn: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'min-width': '48px',
            width: '48px',
            height: '48px',
            border: `1px solid ${variable.darkExtraTransparentColor}`,
            'border-radius': '14px',
            'margin-left': '4px',
            'cursor': 'pointer'
        },
        formSelectDataHelpBtnSvg: { fill: variable.blueColor },
        formSelectDataHelpBtnSvgDanger: { fill: variable.redColor },

        formDateTimeWrapper: {
            width: '100%',
            display: 'flex',
            'align-items': 'flex-end'
        },
        formDateTimeD: media(width, height, 'w', {
            768: {
                width: 'calc(100% - 130px)',
                'padding-right': '8px',
                position: 'relative'
            },
            0: {
                width: '70%',
                'padding-right': '8px',
                position: 'relative'
            }
        }),
        formDateTimeT: media(width, height, 'w', {
            768: {
                width: '130px',
                position: 'relative'
            },
            0: {
                width: '30%',
                position: 'relative'
            }
        }),
        formRadioSwitcher: {
            display: 'flex',
            'align-items': 'center',
            'flex-wrap': 'wrap'
        },
        formRadioSwitcherTitle: {
            'font-weight': 'normal',
            ...uikit.text,
            color: variable.darkColor,
            'margin-right': '12px'
        },
        formRadioSwitcherToggler: {
            width: 'fit-content',
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center',
            'border-radius': '20px',
            'background-color': variable.darkSuperTransparentColor,
            padding: '2px'
        },
        formRadioSwitcherTogglerElement: media(width, height, 'w', {
            768: {
                'border-radius': '18px',
                'text-align': 'center',
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '4px 14px',
                'cursor': 'pointer'
            },
            0: {
                'border-radius': '18px',
                'text-align': 'center',
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '4px 14px'
            }
        }),
        radioSwitcherTogglerElementActive: {
            'background-color': variable.whiteColor,
            'box-shadow': `0px 3px 8px ${variable.blackExtraTransparentColor}, 0px 3px 1px ${variable.blackSuperTransparentColor}`
        },
        formRadioSwitcherTogglerText: {
            ...uikit.small,
            'font-weight': '500',
            'line-height': '1.5',
            'text-align': 'center',
            'flex-shrink': 0,
            position: 'relative',
            'z-index': '1',
            color: variable.blueDarkColor
        },
        formRadioSwitcherTogglerTextActive: { color: variable.blueColor },


        formModalInfoBlockModal: media(width, height, 'w', {
            768: {
                background: variable.greyTransparentHalfColor,
                'border-radius': '8px',
                padding: '16px',
                'margin-bottom': '24px'
            },
            0: {
                background: variable.greyTransparentHalfColor,
                'border-radius': '8px',
                padding: '16px',
                'margin-bottom': '8px'
            }
        }),
        formModalPrevValueTextBlock: {
            display: 'flex',
            'align-items': 'baseline',
            'flex-wrap': 'wrap',
            'margin-bottom': '10px'
        },
        formModalPrevValueText: media(width, height, 'w', {
            768: {
                'font-weight': 'normal',
                ...uikit.subtitle,
                'margin-right': '6px',
                color: variable.darkColor
            },
            0: {
                'font-weight': 'normal',
                ...uikit.subtitle,
                color: variable.darkColor
            }
        }),
        formModalPrevValue: media(width, height, 'w', {
            768: {
                'font-weight': '500',
                ...uikit.subtitle,
                'margin-right': '6px',
                color: variable.blueColor,
                'word-break': 'break-all'
            },
            0: {
                'font-weight': '500',
                ...uikit.subtitle,
                width: '100%',
                color: variable.blueColor,
                'word-break': 'break-all'
            }
        }),
        formModalInfoTextBlock: {
            ...uikit.text,
            color: variable.darkHalfTransparentColor
        },
        formModalSubtitle: media(width, height, 'w', {
            768: {
                ...uikit.title,
                color: variable.darkColor,
                'font-weight': '500',
                'padding-left': '20px',
                'padding-right': '20px',
                'margin-bottom': '32px',
                'text-align': 'center'
            },
            0: {
                ...uikit.title,
                color: variable.darkColor,
                'font-weight': '500',
                'margin-bottom': '20px',
                'padding-left': '35px',
                'padding-right': '35px',
                'text-align': 'center'
            }
        }),
        modalContentWrapper: media(width, height, 'w', {
            768: {
                background: variable.whiteColor,
                'border-radius': '20px',
                padding: '32px',
                position: 'relative',
                width: '576px',
                'max-width': '100%'
            },
            0: {
                display: 'flex',
                'flex-direction': 'column',
                'align-items': 'center',
                background: variable.whiteColor,
                'border-radius': `${'20px'} ${'20px'} 0 0`,
                padding: '24px 12px 34px',
                position: 'relative',
                width: '576px',
                'max-width': '100%',
                'padding-left': '16px',
                'padding-right': '16px'
            }
        }),
        modalCloseBtn: media(width, height, 'w', {
            768: {
                position: 'absolute',
                top: '24px',
                right: '24px',
                width: '24px',
                height: '24px',
                'z-index': 10,
                cursor: 'pointer'
            },
            0: {}
        }),
        modalCloseBtnDecor: {
            background: variable.greyExtraLightColor,
            'border-radius': '100px',
            'margin-bottom': '12px',
            width: '32px',
            height: '4px'
        },

        formInfoTooltip: {
            'margin-left': '5.3px',
            'cursor': 'pointer',
            display: 'flex'
        },
        formInfoTooltipSvg: {
            width: '12px',
            height: '12px',
            fill: variable.greyExtraDarkColor
        },
        formInfoIcon: media(width, height, 'w', {
            768: {
                width: 16,
                height: 16,
                fill: variable.darkHalfTransparentColor
            },
            0: {
                width: 14,
                height: 14,
                fill: variable.darkHalfTransparentColor
            }
        }),
        formCheckboxSwitcher: {
            width: '40px',
            height: '24px',
            'margin-right': '14px',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'border-radius': '20px',
            'background-color': variable.calcColor('dark', 0.2),
            position: 'relative'
        },
        formCheckboxSwitcherCircle: {
            position: 'absolute',
            top: '50%',
            left: '2px',
            width: '20px',
            height: '20px',
            'border-radius': '50%',
            'background-color': variable.whiteColor,
            transform: 'translateY(-50%)',
            transition: variable.transition
        },
        formCheckboxSwitcherCircleActive: {
            left: 'auto',
            right: '2px',
            transition: variable.transition

        },
        formCheckboxSwitcherPending: {
            background: variable.greyDarkColor,
            border: `0.5px solid ${variable.blueDarkExtraTransparentColor}`,
            'box-shadow': `0px 3px 8px  ${variable.blackExtraTransparentColor}, 0px 3px 1px ${variable.blackSuperTransparentColor}`
        },
        formCheckboxSwitcherActive: {
            'border-color': variable.blueColor,
            'background-color': variable.blueColor
        },
        formCheckboxSwitcherEditWrapper: {
            'margin-left': '6px',
            cursor: 'pointer'
        },
        formCheckboxSwitcherEditIcon: media(width, height, 'w', {
            768: {
                width: '20px',
                height: '20px',
                fill: variable.darkHalfTransparentColor
            },
            0: {
                width: '20px',
                height: '20px',
                fill: variable.darkHalfTransparentColor
            }
        }),

        formCheckboxSwitcherEditIconHover: {
            cursor: 'pointer',
            background: variable.greySuperExtraDarkTransparentColor,
            'border-radius': '8px'
        },

        formCheckboxSwitcherEditIconCheck: media(width, height, 'w', {
            768: {
                width: '14px',
                height: '10px',
                fill: variable.greenDeepColor
            },
            0: {
                width: '14px',
                height: '10px',
                fill: variable.greenDeepColor
            }
        }),
        formCheckboxSwitcherEditIconClose: media(width, height, 'w', {
            768: {
                width: '13px',
                height: '13px',
                fill: variable.redColor
            },
            0: {
                width: '13px',
                height: '13px',
                fill: variable.redColor
            }
        }),

        formColorPicker: {
            display: 'flex',
            'align-items': 'center'
        },
        formColorPickerCircle: {
            position: 'relative',
            width: '32px',
            height: '32px',
            'border-radius': '50%',
            border: `2px solid ${variable.darkExtraTransparentColor}`,
            padding: '2px',
            'margin-bottom': '24px'
        },
        formColorPickerCircleHover: { 'border-color': variable.blueColor },
        formColorPickerCircleFooter: {
            width: '26px',
            height: '26px',
            'border-radius': '50%',
            border: `2px solid ${variable.darkExtraTransparentColor}`,
            padding: '2px',
            cursor: 'pointer'

        },
        formColorPickerCircleItem: {
            width: '100%',
            height: '100%',
            'border-radius': '50%',
            cursor: 'pointer'
        },
        formColorPickerWrapper: {
            position: 'absolute',
            top: '120%',
            left: '50%',
            transform: 'translateX(-50%)',
            'background-color': variable.whiteColor,
            'border-radius': '14px',
            padding: '16px',
            'z-index': 9

        },
        formColorPickerContainer: {
            margin: '8px 0 16px 0',
            display: 'flex'
            // 'flex-wrap': 'wrap'
        },
        formColorPickerHeader: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-between',
            width: '100%'
        },
        formColorPickerTitle: {
            ...uikit.small,
            color: variable.greySuperDarkColor,
            'white-space': 'pre'
        },
        formColorPickerInput: {
            width: '90px',
            height: '40px',
            'min-height': '40px',
            'margin-left': '8px',
            padding: '9px'
        },
        formColorPickerInputFocus: {
            width: '90px',
            height: '40px',
            'min-height': '40px',
            'margin-left': '8px',
            padding: '8px'
        },
        formColorPickerButton: {
            ...uikit.flexColumn,
            ...uikit.justifyContentCenter,
            padding: '10px',
            width: '162px',
            height: '48px',
            'text-align': 'center',
            'line-height': '1.25',
            border: `1px solid ${variable.darkExtraTransparentColor}`,
            'border-radius': '14px',
            'font-weight': 500,
            'margin-right': '8px',
            'margin-bottom': '24px',
            cursor: 'pointer'
        },
        formColorPickerButtonHover: { 'background-color': variable.greyExtraDarkColorTransparent },
        formColorPickerAddButton: {
            width: '26px',
            height: '26px',
            color: variable.greyExtraDarkColor,
            'font-size': '1.375rem',
            border: `1px solid ${variable.darkExtraTransparentColor}`,
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'border-radius': '50%',
            cursor: 'pointer',
            'margin-left': '8px'
        },
        formColorPickerHue: {
            width: '26px',
            height: '26px',
            'border-radius': '50%',
            border: `2px solid ${variable.whiteColor}`,
            // padding: '2px',
            transform: 'translate(-50%, -25%)',
            cursor: 'pointer'

        },
        formColorPickerItem: {
            width: '100%',
            height: '100%',
            'border-radius': '50%'
        },
        formSelectMenu: {
            width: 'auto',
            'min-width': '100%',
            margin: 0,
            'border-radius': '12px',
            'overflow': 'hidden'
        },

        formSelectOption: {
            display: 'flex',
            'cursor': 'pointer',
            borderRadius: '8px',
            padding: '8px 16px',
            textAlign: 'left',
            color: variable.darkColor,
            'background-color': variable.whiteColor
        },
        formSelectOptionSelected: { color: variable.blueColor },
        formSelectOptionHover: {
            color: variable.blueColor,
            'background-color': `${variable.greyExtraDarkTransparentColor} !important`
        },
        formSelectOptionCheckboxLabel: {
            ...uikit.text,
            'margin-bottom': '0'
        },
        formSelectMenuList: {
            'cursor': 'pointer',
            color: variable.darkColor,
            borderRadius: '8px',
            padding: '8px 16px',
            textAlign: 'left'
        },
        formSelectControl: {
            display: 'flex',
            'align-items': 'center'
        },
        formSelectIndicatorsContainer: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            display: 'flex',
            right: '0',
            'align-items': 'center'
        },

        formSelectValueContainer: media(width, height, 'w', {
            768: {
                ...uikit.text,
                padding: 0,
                display: 'flex',
                'flex-grow': '1',
                'align-items': 'center',
                'position': 'relative'
            },
            0: {
                ...isSafari ? {
                    'font-size': '16px',
                    'line-height': '1.5rem'
                } : uikit.text,
                padding: 0,
                display: 'flex',
                'flex-grow': '1',
                'align-items': 'center',
                'position': 'relative'
            }
        }),

        formSelectValueContainerMultiple: {
            margin: '-2px',
            'max-width': '100%'
        },
        formSelectMultiValue: {
            'background-color': variable.blueColor,
            'border-radius': '6px',
            padding: '5px 8px',
            ...uikit.noWrap
        },
        formSelectMultiValueLabel: {
            ...uikit.small,
            'line-height': '1',
            'font-weight': '500',
            'margin-right': '4px',
            color: variable.whiteColor,
            ...uikit.noWrap,
            'max-width': 'calc(100% - 18px)'
        },
        formSelectMultiValueRemove: {
            color: variable.whiteColor,
            height: '14px',
            width: '14px',
            cursor: 'pointer',
            display: 'flex',
            'align-items': 'center'
        },
        formSelectDropdownIndicator: {
            padding: 0,
            transition: 'all .2s linear',
            'line-height': '1',
            display: 'flex',
            'align-items': 'center',
            cursor: 'pointer'
        },

        formSelectDropdownIndicatorOpened: { transform: 'rotate(180deg)' },

        formSelectInput: {
            padding: 0,
            margin: 0
        },
        formSelectSingleValue: {
            padding: 0,
            ...uikit.noWrap,
            'max-with': 'calc(100% - 20px)',
            position: 'absolute',
            left: '0',
            top: '50%',
            transform: 'translateY(-50%)'
        },
        formSelectPlaceholder: {
            ...isSafari ? {
                'font-size': '16px',
                'line-height': '1.5rem'
            } : uikit.text,
            'max-with': 'calc(100% - 20px)',
            position: 'absolute',
            left: '0',
            top: '50%',
            transform: 'translateY(-50%)'
        },
        formSelectIndicatorSeparator: { display: 'none' },
        formSelectClearIndicator: {
            display: 'flex',
            'margin-right': '5px',
            opacity: '.5',
            cursor: 'pointer'
        },
        formDropzoneInner: {
            display: 'flex',
            'flex-wrap': 'wrap'
        },

        formDropzoneDeleteButton: {
            'font-weight': 500,
            ...uikit.small,
            color: variable.redDarkColor
        },
        formDeopzoneLink: {
            ...uikit.flexRow,
            ...uikit.alignItemsCenter,
            ...uikit.justifyContentCenter,
            color: 'currentColor'
        },
        formDropzoneImageWrapper: media(width, height, 'w', {
            500: {
                position: 'relative',
                display: 'flex',
                'max-width': '100%',
                'margin-right': '16px',
                'margin-bottom': '22px'
            },
            0: {
                position: 'relative',
                display: 'flex',
                'max-width': '100%',
                'margin-right': '16px',
                'margin-bottom': '22px'
            }
        }),
        formDropzoneButtonsWrapper: {
            position: 'absolute',
            bottom: 0,
            gap: '14px',
            // ...uikit.flexColumn,
            ...uikit.justifyContentCenter,
            left: '50%',
            // top: '332px',
            // left: '116px',
            transform: 'translate(-50%, 50%)',
            'background-color': variable.whiteColor,
            display: 'flex',
            'align-items': 'center',
            // width: '126px',
            height: '32px',
            padding: '6px 12px',
            'border-radius': '18px',
            'box-shadow': `0px 3px 8px ${variable.blackExtraTransparentColor}, 0px 3px 1px ${variable.blackSuperTransparentColor}`
        },
        formDropzoneButtonsLine: {
            height: '20px',
            width: '1px',
            background: '#c3c3c3'
        },

        formDropzoneOpenButtons: {
            cursor: 'pointer',
            'margin-top': '5px'

        },
        formDropzoneOpenLoadButtons: {
            cursor: 'pointer',
            'margin-top': '3px'

        },
        formDropzoneLoadButtons: {
            position: 'absolute',
            bottom: 0,
            // ...uikit.flexColumn,
            ...uikit.justifyContentCenter,
            left: '50%',
            // top: '332px',
            // left: '116px',
            transform: 'translate(-50%, 50%)',
            'background-color': variable.whiteColor,
            // width: '126px',
            height: '32px',
            padding: '6px 12px',
            'border-radius': '18px',
            'box-shadow': `0px 3px 8px ${variable.blackExtraTransparentColor}, 0px 3px 1px ${variable.blackSuperTransparentColor}`,
            cursor: 'pointer'
        },

        formDropzoneImageContent: media(width, height, 'w', {
            768: {
                display: 'flex',
                height: '234px',
                width: '234px',
                'max-width': '100%',
                'overflow': 'hidden',
                'border-radius': '12px'
            },
            0: {
                display: 'flex',
                height: '240px',
                'overflow': 'hidden',
                'max-width': '100%',
                'border-radius': '12px'
            }
        }),

        formDropzonePreviewImage: media(width, height, 'w', {
            500: {},
            0: { 'max-width': '300px' }
        }),
        formDropzoneLoadDrop: media(width, height, 'w', {
            768: {
                width: '234px',
                height: '234px',
                border: `1px dashed ${variable.greyDeepExtraDarkColor}`,
                'background-color': variable.calcColor('greyLight', '0.3'),
                'border-radius': '14px',
                display: 'flex',
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
                'text-align': 'center',
                padding: '18px',
                ...uikit.small,
                color: variable.greySuperDarkColor,
                'margin-right': '24px',
                'margin-bottom': '22px',
                cursor: 'pointer'
            },
            0: { 'display': 'none' }
        }),
        formDropzoneLoadLabelIcon: {
            fill: variable.blueColor,
            marginRight: '12px'
        },
        formDropzoneLoadLabelWrap: media(width, height, 'w', {
            768: {
                width: '100%',
                display: 'flex',
                'align-items': 'center',
                'margin-top': '16px'
            },
            0: {
                order: '-1',
                width: '100%',
                display: 'flex',
                'align-items': 'center'
            }
        }),
        formDropzoneLoadLabel: {
            display: 'flex',
            width: 'fit-content',
            'align-items': 'center',
            'justify-content': 'center',
            padding: '12px 24px',
            border: `2px solid ${variable.darkExtraTransparentColor}`,
            'border-radius': '14px',
            'margin-right': '18px',
            cursor: 'pointer',
            color: variable.blueColor
        },
        formDropzoneText: media(width, height, 'w', {
            768: {
                'max-width': '330px',
                ...uikit.small,
                color: variable.greyDarkColor
            },
            0: {
                'max-width': '330px',
                ...uikit.small,
                color: variable.greyExtraDarkColor
            }
        }),

        formDropzoneLoadImageContainerPreview: media(width, height, 'w', {
            768: {
                display: 'flex',
                'flex-wrap': 'wrap',
                width: 'calc(100% - 258px)'
            },
            0: {
                display: 'flex',
                'flex-wrap': 'wrap',
                width: '100%',
                'margin': '10px auto',
                'justify-content': 'center'
            }
        }),

        formRangeFlex: {
            'display': 'flex',
            'align-items': 'center'
        },
        formRangeSeparator: media(width, height, 'w', {
            768: {
                background: variable.darkHalfTransparentColor,
                'height': '1px',
                'width': '12px',
                'min-width': '12px',
                margin: '0 6px',
                'margin-bottom': '24px'
            },
            0: {
                background: variable.darkHalfTransparentColor,
                'height': '1px',
                'width': '12px',
                'min-width': '12px',
                margin: '0 6px',
                'margin-bottom': '8px'
            }
        }),
        formProductPricesAdd: {
            width: '120px',
            'background-color': variable.blueExtraTransparentColor,
            'border-radius': '8px',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'min-width': '120px',
            'cursor': 'pointer'
        },
        formProductPricesError: {
            position: 'absolute',
            bottom: '-8px',
            left: '0'
        },
        formProductPricesHeaderWrapper: media(width, height, 'w', {
            1200: {
                display: 'flex',
                'align-items': 'flex-start',
                'flex-wrap': 'wrap'
                // 'position': 'relative'
            },
            0: {
                display: 'flex',
                'flex-direction': 'column'
                // 'position': 'relative'
            }
        }),

        formProductPricesHeaderItem: media(width, height, 'w', {
            768: { 'margin-bottom': '16px' },
            0: {}
        }),

        formProductPricesWrapper: media(width, height, 'w', {
            1200: {
                'max-width': '337px',
                'width': '337px',
                'height': '64px',
                'padding-right': '16px',
                position: 'relative',
                'justify-content': 'center'
            },
            768: {
                'max-width': '337px',
                'width': '337px',
                'height': '64px',
                'padding-right': '16px',
                position: 'relative',
                'margin-bottom': '16px'
            },
            0: {
                'max-width': '100%',
                'width': '100%',
                position: 'relative'
            }
        }),
        searchWindowTableWrapper: media(width, height, 'w', {
            992: {
                'align-items': 'center',
                padding: '24px 32px 1px'
            },
            768: {
                'align-items': 'center',
                padding: '12px 24px 1px 24px'
            },
            0: {
                'border-radius': '20px 20px 0 0',
                'align-items': 'center',
                padding: '16px 12px 0',
                background: variable.whiteColor
            }
        }),

        formProductPrices: media(width, height, 'w', {
            768: {
                display: 'flex',
                'overflow-x': 'auto',
                width: '800px',
                'padding-top': '10px'
            },
            0: {
                display: 'flex',
                'overflow-x': 'auto',
                'max-width': '320px',
                'padding-top': '10px'
            }
        }),

        formProductPricesColTabs: media(width, height, 'w', {
            768: {
                'min-width': '250px',
                'width': '250px',
                padding: '8px',
                'border-radius': '8px'
            },
            0: {
                'min-width': '125px',
                'width': '125px',
                padding: '8px',
                'border-radius': '8px'
            }
        }),

        formProductPricesColTabsItem: {
            height: '48px',
            'margin-bottom': '16px',
            display: 'flex',
            'align-items': 'center'
        },

        formProductPricesCol: {
            'display': 'flex',
            'flex-direction': 'column',
            padding: '8px',
            'background': variable.greySuperExtraDarkTransparentColor,
            'margin-right': '8px',
            'text-align': 'center',
            'border-radius': '8px',
            position: 'relative'
        },

        formProductPricesColClose: {
            cursor: 'pointer',
            position: 'absolute',
            right: '-10px',
            top: '-10px',
            width: '20px',
            height: '20px',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'border-radius': '50%',
            'background-color': variable.whiteColor,
            'box-shadow': '0px 3px 8px ${variable.blackExtraTransparentColor}, 0px 3px 1px ${variable.blackSuperTransparentColor}'
        },
        formProductPricesInputWrapper: { width: '104px', 'margin-bottom': '8px' },

        formRadio: media(width, height, 'w', {
            768: {
                display: 'flex',
                'flex-direction': 'row',
                'align-items': 'center',
                'margin-bottom': '16px',
                'cursor': 'pointer'
            },
            0: {
                display: 'flex',
                'flex-direction': 'row',
                'align-items': 'center',
                'margin-bottom': '8px',
                'cursor': 'pointer'
            }
        }),

        formRadioCircleWrap: {
            border: `2px solid ${variable.greyDeepExtraDarkColor}`,
            width: '20px',
            'min-width': '20px',
            height: '20px',
            'margin-right': '10px',
            'border-radius': '20px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center'
        },
        formRadioCircleWrapActive: { border: `2px solid ${variable.blueColor}` },
        formRadioCircle: {
            width: '10px',
            'min-width': '10px',
            height: '10px',
            background: variable.blueColor,
            'border-radius': '10px'
        },

        formSponsorBlock: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'justify-content': 'space-between',
            'margin-bottom': '14px'
        },
        formSponsorTextBlock: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center'
        },

        formSponsorBlockTitle: {
            'font-weight': '500',
            ...uikit.subtitle
        },
        formSponsorTooltip: { width: '258px' },

        formSponsorIconWrap: media(width, height, 'w', {
            768: {
                'margin-left': '6px',
                'margin-bottom': '12px'
            },
            0: { 'margin-left': '6px' }
        }),

        formSponsorTab: {
            width: '137px',
            height: '32px',
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center',
            'border-radius': '20px',
            'background-color': variable.blueDarkExtraTransparentColor,
            position: 'relative',
            'user-select': 'none',
            cursor: 'pointer'
        },

        formSponsorTabElement: {
            width: '68px',
            height: '28px',
            'border-radius': '18px',
            'background-color': variable.whiteColor,
            color: variable.blueDeepLightColor,
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            position: 'absolute'
        },

        formSponsorTabText: {
            width: '100%',
            padding: '0 25px 0 25px',
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'justify-content': 'space-between'
        },
        formSponsorTabTextActive: {
            color: variable.blueDeepLightColor,
            'font-weight': '500',
            ...uikit.small
        },
        formSponsorTabTextNotActive: {
            color: variable.blueDarkColor,
            'font-weight': '500',
            ...uikit.small
        },
        formUserBlockWrapper: media(width, height, 'w', {
            768: {
                'margin-top': '16px',
                'border-radius': '12px',
                'background-color': variable.greyExtraDarkTransparentColor,
                'padding-top': '16px',
                'padding-right': '16px',
                'padding-bottom': '8px',
                'padding-left': '16px',
                overflow: 'hidden'
            },
            0: {
                'margin-top': '8px',
                'border-radius': '12px',
                'background-color': variable.greyExtraDarkTransparentColor,
                'padding-top': '16px',
                'padding-right': '16px',
                'padding-bottom': '8px',
                'padding-left': '16px',
                overflow: 'hidden'
            }
        }),

        formUserBlock: {
            'padding-bottom': '16px',
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center'
        },

        formUserBlockImg: {
            position: 'relative',
            // padding: '0 10px',
            'margin-right': '16px',
            ...uikit.flexColumn,
            ...uikit.alignItemsCenter,
            'flex-shrink': 0
        },
        formUserAvatarImg: {
            width: '96px',
            height: '96px'
        },

        formUserAvatarCanvas: {
            width: '102px',
            height: '102px',
            'border-radius': '100%',
            'position': 'absolute',
            left: 0
        },

        formUserPosition: {
            position: 'relative',
            padding: '4px 7px',
            'border-radius': '16px',
            'font-weight': 'bold',
            color: variable.whiteColor,
            left: '50%',
            'min-width': '93px',
            'text-align': 'center',
            bottom: '0',
            ...uikit.small,
            'line-height': '1.14',
            background: variable.greyDeepDarkColor,
            'box-shadow': `0px 6px 30px ${variable.blackExtraTransparentColor}`,
            transform: 'translateX(-50%) translateY(-1.25rem)',
            'max-width': '180px'
        },
        formUserSubtitle: {
            'font-weight': '500',
            ...uikit.subtitle,
            color: variable.darkColor
        },
        formUserLogin: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'justify-content': 'flex-start',
            padding: '8px 0'
        },
        formUserLoginIconWrap: {
            borderRadius: '35px',
            width: '16px',
            height: '16px',
            // background-color: variable.blueColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            'margin-right': '6px'
        },

        formUserLoginIcon: {
            width: '16px',
            height: '16px',
            fill: variable.blueColor
        },
        formUserLoginText: {
            ...uikit.small,
            'line-height': '1.14'
        },
        formUserLoginTextMR: { 'margin-right': '4px' },
        formUserLoginTextLabel: { color: variable.darkHalfTransparentColor },


        formUserContactBlock: media(width, height, 'w', {
            768: {
                display: 'flex',
                'flex-direction': 'row',
                'flex-wrap': 'wrap'
            },
            0: {
                display: 'flex',
                'flex-direction': 'column'
            }
        }),
        formUserContactItem: media(width, height, 'w', {
            550: {
                display: 'flex',
                'flex-direction': 'row',
                'align-items': 'center',
                'margin-bottom': '8px',
                'min-width': '230px',
                flex: 'none'
            },
            0: {
                display: 'flex',
                'flex-direction': 'row',
                'align-items': 'center',
                'margin-bottom': '8px',
                flex: 1
            }
        }),
        formUserContactItemIcon: {
            width: '20px',
            height: '20px',
            display: 'flex',
            marginRight: '8px'
        },

        formGroupButtonItemWrap: media(width, height, 'w', {
            768: {
                display: 'flex',
                'flex-direction': 'row',
                flex: 1,
                margin: '0 -6px',
                'justify-content': 'center',
                'flex-wrap': 'wrap'
            },
            0: {
                display: 'flex',
                'flex-direction': 'row',
                flex: 1,
                margin: '0 0 0 -4px',
                'justify-content': 'center',
                'flex-wrap': 'wrap'
            }
        }),
        formGroupButtonItem: media(width, height, 'w', {
            768: {
                'padding': '0 6px',
                flex: 1
            },
            0: {
                'padding': '0 4px',
                flex: 1
            }
        }),

        formBottomButtonGroup: media(width, height, 'w', {
            768: {
                'flex-grow': 1,
                display: 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-end',
                'justify-content': 'center',
                width: '334px',
                'max-width': '100%',
                'margin-left': 'auto',
                'margin-right': 'auto'
            },
            0: {
                'flex-grow': 1,
                display: 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-end',
                'justify-content': 'center',
                width: '100%',
                'max-width': '100%',
                'margin-left': 'auto',
                'margin-right': 'auto'
            }
        }),
        formBottomButtonGroupLarge: {
            width: '100%',
            'max-width': '100%'
        },

        formButtonPrimary: media(width, height, 'w', {
            768: {
                ...uikit.button,
                color: variable.whiteColor,
                'background-color': variable.orangeColor
            },
            0: {
                ...uikit.button,
                color: variable.whiteColor,
                'background-color': variable.orangeColor,
                'height': '60px'
            }
        }),
        formButtonPrimaryHover: { 'background-color': variable.orangeDarkColor },
        formButtonPrimaryFocus: { 'background-color': variable.orangeLightColor },

        buttonGray: media(width, height, 'w', {
            768: {
                ...uikit.button,
                'background-color': variable.greyExtraLightColor,
                color: variable.darkColor
            },
            0: {
                ...uikit.button,
                'background-color': variable.greyExtraLightColor,
                color: variable.darkColor,
                'height': '60px'
            }
        }),
        buttonGrayHover: { 'background-color': variable.greyMainColor },
        buttonGrayFocus: { 'background-color': variable.greyMainColor },
        backgroundhtmlInfo: {
            'background-color': 'rgba(143,143,171,0.08)',
            'border-radius': '10px',
            padding: '10px',
            'margin-bottom': '1.5rem',
            'font-size': '14px'
        },
        multiSelectDataWidgetTableTop: {
            display: 'grid',
            'grid-template-columns': '0.5fr 1fr',
            padding: '10px',
            background: '#dfe1e7',
            'border-radius': '6px'
        },
        multiSelectDataWidgetTable: {
            'margin-top': '10px',
            width: '100%'
        },
        multiSelectDataWidgetTableInner: {
            display: 'grid',
            'grid-template-columns': '0.5fr 1fr 0.3fr',
            'border-bottom': '1px solid #dfe1e7',
            padding: '5px 0'
        }
    }
};
export default stylesheets;