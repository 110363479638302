// @generated: @expo/next-adapter@2.1.5
import React, {
    useRef, useCallback, useState, useContext, useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { Form, Div } from '../../hybrid/wrappers';
import FormButtons from './form-control-buttons/FormButtons';
import getStyles from '../../project/styles/widget-styles/form-styles';
import Styles from '../../common/decorators/Styles';
import UserContext from '../../common/utils/getContext';
import FormSwitcher from './FormSwitcher';
import AddedComponents from './AddedComponents';
import { sendFormDataFunc } from './formUtils';
import { useRouter } from 'next/router';

const FormComponent = ({
    data,
    funcs = {},
    formName = 'form',
    formStyles = {},
    sendData,
    styles,
    add,
    buttons,
    setFormDataMain,
    formDataMain,
    buttonNotShow,
    groupFormDataError,
    message,
    customData = {},
    attempts,
    tryCount,
    isContainerBody
}) => {
    const router = useRouter()

    const {
        userToken, setAlertData, lang, localization
    } = useContext(UserContext);
    const addedTop = useMemo(() => add && add.filter((item) => item.position === 'top'), [add]);
    const addedMiddle = useMemo(() => add && add.filter((item) => item.position === 'middle'), [add]);
    const addedBottom = useMemo(() => add && add.filter((item) => item.position !== 'top' && item.position !== 'middle'), [add]);
    const getFormData = useMemo(() =>
        data && data.length
            ? data.reduce((accum, item) => {
                if (item.disabled !== true) {
                    if (item.value || item.value === null) accum[item.attribute] = item.value;
                    else accum[item.attribute] = null;
                }
                return accum;
            }, {})
            : {}
    , [data]);

    const formData = useRef(getFormData);
    const [captchaCount, setCaptchaCount] = useState(+attempts);
    const [allowedSend, setAllowedSend] = useState(true);
    const [fieldsErr, setFieldsErr] = useState(groupFormDataError || {});

    useEffect(() => {
        (async () => {
            const num = await +attempts;
            setCaptchaCount(num);
        })();
    }, [attempts])

    useEffect(() => {
        if (groupFormDataError) setFieldsErr(groupFormDataError);
    }, [groupFormDataError]);

    const setFormData = useCallback((key, val) => {
        if (setFormDataMain) { return setFormDataMain(key, typeof val === 'string' && val.length === 0 ? null : val); }

        return formData.current[key] = typeof val === 'string' && val.length === 0 ? null : val;
    }, [formData, setFormDataMain]);

    const sendFormData = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (formData.current.hasOwnProperty('g-recaptcha-response') && formData.current['g-recaptcha-response'].attribute === 'auth') {
            if (formData.current.login && formData.current.password) {
                if (!formData.current.login || !formData.current.password || typeof formData.current['g-recaptcha-response'] === 'object') {
                    setCaptchaCount(+captchaCount + 1);
                }
            }
        }
        sendFormDataFunc(
            formDataMain || formData.current,
            allowedSend,
            funcs,
            sendData,
            setAllowedSend,
            setFieldsErr,
            setAlertData,
            userToken,
            lang,
            message,
            router,
            localization?.currency
        );
    }, [localization?.currency, formDataMain, allowedSend, funcs, sendData, setAlertData, userToken, lang, message, router, captchaCount]);

    if (!(data && Array.isArray(data) && data.length)) return null;

    return (
        <Form
            styles={{ ...styles.form, ...customData?.stylesForm ? customData.stylesForm : {} }}
            autoComplete='off'
            onSubmit={sendFormData}
        >
            {
                !!addedTop && !!addedTop.length
                    && <AddedComponents add={addedTop} />
            }
            {
                customData?.fieldsWrapper
                    ?
                    <Div {...customData?.fieldsWrapper}>
                        <FormSwitcher
                            data={data}
                            formName={formName}
                            funcs={funcs}
                            formStyles={formStyles}
                            formData={formDataMain || formData.current}
                            setFormDataMain={setFormDataMain}
                            fieldsErr={fieldsErr}
                            customData={customData}

                            sendFormData={sendFormData}
                            message={message}
                            setFormData={setFormData}
                            aptchaCount={captchaCount}
                            tryCount={tryCount}
                            isContainerBody={isContainerBody}
                        />
                    </Div>

                    : <FormSwitcher
                        data={data}
                        formName={formName}
                        funcs={funcs}
                        formStyles={formStyles}
                        formData={formDataMain || formData.current}
                        setFormDataMain={setFormDataMain}
                        fieldsErr={fieldsErr}
                        customData={customData}

                        sendFormData={sendFormData}
                        message={message}
                        setFormData={setFormData}
                        captchaCount={captchaCount}
                        tryCount={tryCount}
                        isContainerBody={isContainerBody}
                    />

            }

            {
                !!addedMiddle && !!addedMiddle.length
                    && <AddedComponents add={addedMiddle} />
            }

            {
                buttonNotShow
                    ? null
                    :
                    <FormButtons
                        formName={formName}
                        buttons={buttons}
                        sendFormData={sendFormData}
                        customFormButton={customData?.customFormButton}
                        buttonText={customData?.customFormButton?.text}
                    />

            }

            {
                !!addedBottom && !!addedBottom.length
                    && <AddedComponents add={addedBottom} />
            }
        </Form>
    );
};

FormComponent.propTypes = {
    data: PropTypes.array,
    funcs: PropTypes.object,
    formName: PropTypes.string,
    formStyles: PropTypes.object,
    sendData: PropTypes.object,
    styles: PropTypes.object,
    add: PropTypes.array,
    buttons: PropTypes.array,
    formDataMain: PropTypes.object,
    setFormDataMain: PropTypes.func,
    buttonNotShow: PropTypes.bool,
    groupFormDataError: PropTypes.object,
    message: PropTypes.object,
    customData: PropTypes.object,
    attempts: PropTypes.number,
    tryCount: PropTypes.number
};

export default Styles(FormComponent, getStyles);