import React, {
    useMemo, useState, useRef, useEffect, useContext
} from 'react';
import {
    Portal, Div, svgSprite, Tooltip, A
} from '../../../hybrid/wrappers';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
import PropTypes from 'prop-types';
import UserContext from '../../../common/utils/getContext';
import T from '../../../common/components/T';

const WrapperButton = (props) => {
    if (props.hint && props.href){
        return (
            <A {...props}>
                <Tooltip text={props.hint} styles={{ 'display': 'flex', 'align-items': 'center' }}>{props.children}</Tooltip>
            </A>
        )
    }
    if (props.hint){
        return (
            <Div {...props}>
                <Tooltip text={props.hint} styles={{ 'display': 'flex', 'align-items': 'center' }}>{props.children}</Tooltip>
            </Div>
        )
    }
    if (props?.href) return <A {...props}>{props.children}</A>
    return <Div {...props}>{props.children}</Div>
}

const TooltipBlock = (props) => {
    return <Tooltip text={props.hint} styles={{ 'display': 'flex', 'align-items': 'center' }}>{props.children}</Tooltip>
}
const Buttons = ({
    tbodyRowIndexDel, gridStyles, getHandlers, styles, val, name, linkHref
}) => {
    const ref = useRef(false)
    const { lang } = useContext(UserContext);
    const handlers = getHandlers()

    const [showModal, setShowModal] = useState(false);
    const widthDimensions = useWindowDimensions();
    const mobile = useMemo(() => widthDimensions < 768, [widthDimensions]);
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (showModal && ref.current && !ref.current.contains(e.target)) {
                setShowModal(false)
            }
        }

        document.addEventListener('click', checkIfClickedOutside)
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [showModal])

    return (
        <Div
            ref={ref}
            styles={gridStyles[name]?.wrapper?.styles || {
                ...styles.flexRow, ...styles.alignItemsCenter, ...styles.justifyContentCenter
            }}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >

            {
                handlers?.createId
                &&
                <Div
                    {...getHandlers('createId')}
                    styles={gridStyles[name]?.destroyIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.destroyIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                >
                    {
                        svgSprite('folder-plus',
                            gridStyles[name]?.destroyIco?.styles || { style: { ...styles.tableButtonIcon, ...styles.tableButtonIconNew } })
                    }
                </Div>

            }
            {
                val.comment
                &&
                <>
                    {
                        mobile ?

                            val.comment.modal && val.comment.modal.show ?
                                <Portal closeHandler={val.comment.closeModal}>
                                    <val.commentModal val={val}/>
                                </Portal> : null
                            :
                            <>
                                <val.commentModal val={val}/>
                            </>
                    }

                    <Div
                        {...getHandlers('comment')}
                        styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                        effects={gridStyles[name]?.updateIcoWrap?.effects || {
                            hover: styles.tableButtonHover,
                            focus: styles.tableButtonActive,
                            active: styles.tableButtonActive
                        }
                        }
                    >
                        {
                            svgSprite(!val?.comment?.value ? 'comment-add' : 'comment-lines',
                                gridStyles[name]?.updateIco?.styles || { fill: !val?.comment?.value ? [styles.variable.greyExtraDarkColor, styles.variable.blueColor] : styles.variable.blueColor, style: styles.tableButtonIcon })
                        }
                    </Div>
                </>


            }
            {
                val.data && Object.keys(val.data).length
                    ? Object.keys(val.data).map((key) =>

                        <WrapperButton
                            {...getHandlers(key)}
                            key={`${tbodyRowIndexDel}${key}`}
                            styles={{
                                ...gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb },
                                ...gridStyles[name]?.[key]?.styles || {},
                                ...val.data[key]?.variation ? gridStyles[name]?.[key]?.[val.data[key]?.variation]?.styles || {} : {}
                            }}
                            effects={gridStyles[name]?.updateIcoWrap?.effects || gridStyles[name]?.[key]?.effects || {
                                hover: styles.tableButtonHover,
                                focus: styles.tableButtonActive,
                                active: styles.tableButtonActive
                            }}
                            hint={val.data[key].hint || false}
                        >
                            {
                                val.data[key].icon ?
                                    svgSprite(val.data[key].icon, {
                                        ...styles.tableButtonIcon,
                                        ...gridStyles[name]?.[key]?.iconStyles,
                                        ...val.data[key]?.variation ? gridStyles[name]?.[key]?.[val.data[key]?.variation]?.iconStyles || {} : {}
                                    })
                                    : null
                            }
                        </WrapperButton>) : null
            }
            {
                val.view
                &&
                <WrapperButton
                    href={val.view.href}
                    {...(val.view?.href ? {} : getHandlers('view'))}
                    styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.updateIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                    hint={val.view.hint || false}
                >
                    {
                        svgSprite(val.view.active ? 'eye-slash' : 'eye',
                            gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                    }
                </WrapperButton>

            }
            {
                val.update
                && (linkHref && !val.update?.href ? <Div>
                    <A styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                        effects={gridStyles[name]?.updateIcoWrap?.effects || {
                            hover: styles.tableButtonHover,
                            focus: styles.tableButtonActive,
                            active: styles.tableButtonActive
                        }
                        } title={
                            svgSprite(val.updateIcon || 'pen-field',
                                gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                        } href={`/${lang}/admin/store/order/lisp/edit/${val.id}`}/>
                </Div> :
                    <WrapperButton
                        href={val.update?.href}
                        {...(val.update?.href ? {} : getHandlers('update'))}
                        styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                        effects={gridStyles[name]?.updateIcoWrap?.effects || {
                            hover: styles.tableButtonHover,
                            focus: styles.tableButtonActive,
                            active: styles.tableButtonActive
                        }
                        }
                        hint={val.update.hint || false}

                    >
                        {
                            svgSprite(val.updateIcon || 'pen-field',
                                gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                        }
                    </WrapperButton>)

            }
            {
                val?.act
                &&
                <WrapperButton
                    hint={val.act?.hint || false}
                >
                    <A styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                        effects={gridStyles[name]?.updateIcoWrap?.effects || {
                            hover: styles.tableButtonHover,
                            focus: styles.tableButtonActive,
                            active: styles.tableButtonActive
                        }
                        } title={
                            svgSprite('download',
                                gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                        } href={val?.act?.act}/>
                </WrapperButton>

            }
            {
                val?.viewAct
                &&
                <WrapperButton
                    hint={val.viewAct?.hint || false}
                    {...getHandlers('viewAct')}
                    styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.updateIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                >
                    {
                        svgSprite('eye',
                            gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                    }
                </WrapperButton>

            }

            {
                val?.restore
                &&
                <>
                    <WrapperButton
                        {...getHandlers('restore')}
                        styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                        effects={gridStyles[name]?.updateIcoWrap?.effects || {
                            hover: styles.tableButtonHover,
                            focus: styles.tableButtonActive,
                            active: styles.tableButtonActive
                        }
                        }
                        hint={val.restore.hint || false}
                    >
                        {
                            svgSprite('check-circle',
                                gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                        }
                    </WrapperButton>
                </>

            }
            {
                val?.destroy
                &&
                <WrapperButton
                    {...getHandlers('destroy')}
                    styles={gridStyles[name]?.destroyIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.destroyIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                    hint={val.destroy.hint || false}
                >
                    {
                        svgSprite('trash',
                            gridStyles[name]?.destroyIco?.styles || { style: { ...styles.tableButtonIcon, ...styles.tableButtonIconDelete } })
                    }
                </WrapperButton>

            }
            {
                val?.settings
                &&
                <WrapperButton
                    {...getHandlers('settings')}
                    styles={gridStyles[name]?.settingsIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.settingsIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                    hint={val.settings.hint || false}
                >
                    {
                        svgSprite('gear',
                            gridStyles[name]?.settingsIco?.styles || { style: { ...styles.tableButtonIcon } })
                    }
                </WrapperButton>

            }
            {
                val.statistic
                &&
                <WrapperButton
                    href={val.statistic?.href}
                    {...(val.statistic?.href ? {} : getHandlers('statistic'))}
                    styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.updateIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                    hint={val.statistic.hint || false}
                >
                    {
                        svgSprite('graph-bars',
                            gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                    }
                </WrapperButton>

            }
            {
                val.add
                &&
                <WrapperButton
                    {...getHandlers('add')}
                    styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.updateIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                    hint={val.add.hint || false}
                >
                    {
                        svgSprite('plus',
                            gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                    }
                </WrapperButton>

            }
            {
                val.shop_settings
                && (linkHref ?<TooltipBlock
                    hint={<T textName='adminSwitchButton-shop_settings' defaultStr='Настройки' page='all'/>}
                > <Div>
                        <A styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                            effects={gridStyles[name]?.updateIcoWrap?.effects || {
                                hover: styles.tableButtonHover,
                                focus: styles.tableButtonActive,
                                active: styles.tableButtonActive
                            }
                            } title={
                                svgSprite(val.updateIcon || 'menu-admin',
                                    gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                            } href={`/${lang}/admin/store/settings${val.id ? `?id=${val.id}` : ''}`}/>
                    </Div></TooltipBlock> : <WrapperButton
                    {...getHandlers('shop_settings')}
                    styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.updateIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                    hint={<T textName='adminSwitchButton-shop_settings' defaultStr='Настройки' page='all'/>}

                >
                    {
                        svgSprite('menu-admin',
                            gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                    }
                </WrapperButton>
                )

            }
            {
                val.shop_update
                && (linkHref
                    ? <TooltipBlock
                        hint={<T textName='adminSwitchButton-shop_update' defaultStr='Редактировать' page='all'/>}
                    > <Div>
                            <A styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                                effects={gridStyles[name]?.updateIcoWrap?.effects || {
                                    hover: styles.tableButtonHover,
                                    focus: styles.tableButtonActive,
                                    active: styles.tableButtonActive
                                }}
                                title={
                                    svgSprite(val.updateIcon || 'pen-field',
                                        gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                                }
                                href={`/${lang}/admin/shops/edit/${val.id}`}
                            />
                        </Div></TooltipBlock>
                    : <WrapperButton
                        {...getHandlers('shop_update')}
                        styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                        effects={gridStyles[name]?.updateIcoWrap?.effects || {
                            hover: styles.tableButtonHover,
                            focus: styles.tableButtonActive,
                            active: styles.tableButtonActive
                        }}
                        hint={<T textName='adminSwitchButton-shop_update' defaultStr='Редактировать' page='all'/>}

                    >
                        {
                            svgSprite('pen-field',
                                gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                        }
                    </WrapperButton>)
            }
            {
                val.shop_categories
                && (linkHref ? <TooltipBlock
                    hint={<T textName='adminSwitchButton-shop_categories' defaultStr='Категории' page='all'/>}
                ><Div>
                        <A styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                            effects={gridStyles[name]?.updateIcoWrap?.effects || {
                                hover: styles.tableButtonHover,
                                focus: styles.tableButtonActive,
                                active: styles.tableButtonActive
                            }
                            } title={
                                svgSprite(val.updateIcon || 'menu-category',
                                    gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                            } href={`/${lang}/admin/store/category${val.id ? `?id=${val.id}` : ''}`}/>
                    </Div></TooltipBlock> : <WrapperButton
                    {...getHandlers('shop_categories')}
                    styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.updateIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                    hint={<T textName='adminSwitchButton-shop_categories' defaultStr='Категории' page='all'/>}

                >
                    {
                        svgSprite('menu-category',
                            gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                    }
                </WrapperButton>)


            }
            {
                val.shop_products
                && (linkHref ?<TooltipBlock
                    hint={<T textName='adminSwitchButton-shop_products' defaultStr='Товары' page='all'/>}
                > <Div>
                        <A styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                            effects={gridStyles[name]?.updateIcoWrap?.effects || {
                                hover: styles.tableButtonHover,
                                focus: styles.tableButtonActive,
                                active: styles.tableButtonActive
                            }
                            } title={
                                svgSprite(val.updateIcon || 'box',
                                    gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                            } href={`/${lang}/admin/store/product${val.id ? `?id=${val.id}` : ''}`}/>
                    </Div> </TooltipBlock> : <WrapperButton
                    {...getHandlers('shop_products')}
                    styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.updateIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                    hint={<T textName='adminSwitchButton-shop_products' defaultStr='Товары' page='all'/>}

                >
                    {
                        svgSprite('box',
                            gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                    }
                </WrapperButton>)


            }
            {
                val.shop_orders
                && (linkHref ?<TooltipBlock
                    hint={<T textName='adminSwitchButton-shop_orders' defaultStr='Заказы' page='all'/>}
                > <Div>
                        <A styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                            effects={gridStyles[name]?.updateIcoWrap?.effects || {
                                hover: styles.tableButtonHover,
                                focus: styles.tableButtonActive,
                                active: styles.tableButtonActive
                            }
                            } title={
                                svgSprite(val.updateIcon || 'menu-orders',
                                    gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                            } href={`/${lang}/admin/store/order${val.id ? `?id=${val.id}` : ''}`}/>
                    </Div></TooltipBlock> : <WrapperButton
                    {...getHandlers('shop_orders')}
                    styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.updateIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                    hint={<T textName='adminSwitchButton-shop_orders' defaultStr='Заказы' page='all'/>}

                >
                    {
                        svgSprite('menu-orders',
                            gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                    }
                </WrapperButton>)


            }

            {
                val.shop_baner
                && (linkHref ?<TooltipBlock
                    hint={<T textName='adminSwitchButton-shop_baner' defaultStr='Слайдер' page='all'/>}
                ><Div>
                        <A styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                            effects={gridStyles[name]?.updateIcoWrap?.effects || {
                                hover: styles.tableButtonHover,
                                focus: styles.tableButtonActive,
                                active: styles.tableButtonActive
                            }
                            } title={
                                svgSprite(val.updateIcon || 'painting',
                                    gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                            } href={`/${lang}/admin/store/slider${val.id ? `?id=${val.id}` : ''}`}/>
                    </Div></TooltipBlock> : <WrapperButton
                    {...getHandlers('shop_baner')}
                    styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.updateIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                    hint={<T textName='adminSwitchButton-shop_baner' defaultStr='Слайдер' page='all'/>}

                >
                    {
                        svgSprite('painting',
                            gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                    }
                </WrapperButton>)


            }
            {
                val?.sendNotification
                &&
                <WrapperButton
                    hint={val.sendNotification?.hint || false}
                    {...getHandlers('sendNotification')}
                    styles={gridStyles[name]?.updateIcoWrap?.styles || { ...styles.tableButton, ...styles.tableButtonHoverWeb }}
                    effects={gridStyles[name]?.updateIcoWrap?.effects || {
                        hover: styles.tableButtonHover,
                        focus: styles.tableButtonActive,
                        active: styles.tableButtonActive
                    }
                    }
                >
                    {
                        svgSprite('menu-communication',
                            gridStyles[name]?.updateIco?.styles || { style: styles.tableButtonIcon })
                    }
                </WrapperButton>

            }

        </Div>
    );
};

Buttons.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    styles: PropTypes.object,
    name: PropTypes.string
};

export default Buttons;